.emailsignup-main-wrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 60px;
}

.image-style {
  object-fit: cover;
  height: 10vh;
}

.emailsignup-title {
  padding: 20px 0 0 0;

  font-size: 30px;
  font-weight: 500;

  text-align: center;
  margin-top: 20px;
}

.emailsignup-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  /* height: 38.7vh; */
}

.emailsignup-content-wrapper > * {
  width: 75%;
}

.emailsignup-btn-submit {
  margin: 12.5px 0;
}

@media (min-width: 778px) {
  .emailsignup-content-wrapper > * {
    width: 60%;
  }

  .image-style {
    height: 15vh !important;
  }

  .emailsignup-title {
    padding: 20px 0 0 8%;

    font-size: 30px;
    font-weight: 500;

    text-align: left;
  }
}
